import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import * as moment from 'moment';
import * as dayjs from 'dayjs';
// import plugin
import 'dayjs/locale/es-us'; // import locale

@Component({
  selector: 'app-datetime-range-picker',
  templateUrl: './datetime-range-picker.component.html',
  styleUrls: ['./datetime-range-picker.component.sass'],
})
export class DatetimeRangePickerComponent implements OnInit, AfterViewInit {
  @Input() disabled = true;
  @Input() initialDates;
  @Output() selectionChanged = new EventEmitter<any>();
  bsRangeValue: any = [
    moment().subtract(1, 'months').toDate(),
    moment().toDate(),
  ];
  datepickerConfig = {
    containerClass: 'theme-blue',
    dateInputFormat: 'YYYY-MM-DD Z',
    rangeInputFormat: 'YYYY-MM-DD Z',
  };
  selected;
  locale = {
    format: 'YYYY/MM/DD',
    displayFormat: 'YYYY/MM/DD',
  };

  constructor() {
    dayjs.locale('es-us');
  }

  ngOnInit(): void {
    dayjs.locale('es-us');

    this.selected = {
      startDate: this.initialDates
        ? this.initialDates[0]
        : dayjs().set('hour', 0).set('minute', 0),
      endDate: this.initialDates
        ? this.initialDates[1]
        : dayjs().add(1, 'month').set('hour', 23).set('minute', 59),
    };
  }
  ngAfterViewInit(): void {}

  getDateLabel() {
    const startTime = this.selected.startDate.format('DD/MM/YYYY');
    const endTime = this.selected.endDate.format('DD/MM/YYYY');
    return `${startTime} - ${endTime}`;
  }
  getTimeLabel() {
    const startTime = this.selected.startDate.format('HH:mm');
    const endTime = this.selected.endDate.format('HH:mm');
    return `${startTime} - ${endTime}`;
  }

  choosedDate(data) {
    console.log(data);
    this.selected = data;
    this.selectionChanged.emit({
      startDate: this.selected.startDate,
      endDate: this.selected.endDate,
    });
  }
}
