<div
  class="modal fade"
  bsModal
  #staticModal="bs-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
  [config]="config"
  (onHide)="handler()">
  <div class="modal-dialog modal-tutorial modal-lg m-t-xxl">
    <div class="modal-content">
      <div class="modal-body">
        <mat-horizontal-stepper #stepper="matHorizontalStepper" [linear]="true">
          <mat-step *ngFor="let step of steps; let index = index">
            <div class="stepper-content">
              <div class="description">
                <h2 class="title">{{ step.title | translate }}</h2>
              </div>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
        <div
          class="step-icons-container"
          *ngIf="stepper?.selectedIndex !== steps.length - 1">
          <img
            *ngFor="
              let icon of stepsIcons;
              let index = index;
              let first = first;
              let last = last
            "
            class="step-icon"
            [ngClass]="{
              active: stepper?.selectedIndex === index,
              center: index === 1,
              left: first,
              right: last
            }"
            [src]="
              stepper?.selectedIndex === index ? icon.active : icon.inactive
            "
            [srcset]="
              stepper?.selectedIndex === index
                ? icon.activeSourceSet
                : icon.inactiveSourceSet
            "
            [alt]="icon?.title | translate" />
        </div>

        <div
          class="step-icons-container full"
          *ngIf="stepper?.selectedIndex === steps.length - 1">
          <img
            [src]="steps[steps.length - 1]?.source"
            [srcset]="steps[steps.length - 1]?.sourceSet"
            [alt]="steps[steps.length - 1]?.title | translate"
            class="Group-10" />
        </div>
      </div>
      <div class="modal-footer">
        <div class="stepper-footer">
          <!-- <button class="btn btn-skip" (click)="finishTutorial()">{{ "ONBOARDING.SKIP" | translate }}</button> -->

          <div class="onboarding-steps">
            <div
              class="stepper-icon"
              *ngFor="let step of steps; let index = index"
              (click)="changeStepper(index)"
              [ngClass]="{
                'stepper-icon-selected': stepper?.selectedIndex >= index
              }"></div>
          </div>

          <div class="change-buttons">
            <button
              mat-button
              color="primary"
              class="text-uppercase"
              *ngIf="stepper?.selectedIndex !== steps.length - 1"
              (click)="next()">
              {{ 'ONBOARDING.NEXT' | translate }}
            </button>
            <button
              mat-raised-button
              color="primary"
              class="text-uppercase"
              (click)="finishTutorial()"
              *ngIf="stepper?.selectedIndex === steps.length - 1">
              {{ 'ONBOARDING.FINISH' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
