<div class="mx-auto mx-5 text-center">
  <div>
    <img
      src="/assets/img/onboarding/image4.svg"
      width="400px,"
      height="300px" />
  </div>
  <div class="text-center">
    <div class="title font-weight-bold my-3">
      ¿Estas listo para tener control de las entregas?
    </div>

    <div class="title font-weight-bold my-3">Bienvenido a</div>

    <img
      class="my-3"
      src="/assets/img/fleetflex_tracklink_logo_negro.png"
      width="250,"
      height="50px" />
  </div>

  <div class="mt-4">
    <button mat-button class="btn-fleetflex" (click)="close()">Empezar</button>
  </div>
</div>
