<div
  class="modal fade"
  bsModal
  #staticModal="bs-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
  [config]="config">
  <div class="modal-dialog modal-tutorial modal-lg m-t-xxl">
    <div class="modal-content">
      <div class="modal-body">
        <h1 class="title">{{ 'PROFILE-MODAL.TITLE' | translate }}</h1>
        <div class="profile-img-container">
          <img
            class="img-responsive"
            src="./assets/img/profile-completed.png"
            srcset="
              ./assets/img/profile-completed@2x.png 2x,
              ./assets/img/profile-completed@3x.png 3x
            "
            alt="Profile completed" />
        </div>
      </div>
      <div class="modal-footer">
        <div class="profile-btn-container">
          <button
            mat-raised-button
            color="primary"
            class="text-uppercase"
            (click)="hideStaticModal()">
            {{ 'DRIVERS.INVITATIONS.FINISH' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-onboarding-tutorial #tutorialModal></app-onboarding-tutorial>
