import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ApiRequestsService } from '../../../api';
import { HelpersService, CustomLoadingService, AnalyticsService } from '../..';
import { isUndefined } from 'lodash';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-import-routes',
  templateUrl: './import-routes.component.html',
  styleUrls: ['./import-routes.component.sass'],
})
export class ImportRoutesComponent implements OnInit {
  @ViewChild('staticModal') staticModal: ModalDirective;

  @Input() file: FormData;
  @Output() importCompleted: EventEmitter<any> = new EventEmitter();

  @Output() retryImport = new EventEmitter<boolean>();

  @Output() assignRoute = new EventEmitter<any>();

  @Output() updateRoutes = new EventEmitter<Boolean>();

  secondTabColumns = ['field', 'sheet', 'line', 'error'];
  routesErrorsTab = ['route', 'error'];
  routesArr: Array<any> = [];
  routesError: Array<any> = [];
  routesCount: any;
  response: boolean = false;
  load: boolean = false;
  selectedIndex: number = 0;
  errors: Array<any> = [];
  routesImported: any;

  constructor(
    private api: ApiRequestsService,
    private helper: HelpersService,
    private loading: CustomLoadingService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: any) {
    if (changes.file && !isUndefined(changes.file.currentValue)) {
      this.response = false;
      this.selectedIndex = 0;
      this.routesArr = [];
      this.routesError = [];
      this.preImportFile(this.file);
    }
  }

  /**
   * Show import Modal
   */
  public showModal(): void {
    this.staticModal.show();
  }

  /**
   * Hide import Modal
   */
  hideStaticModal(): void {
    this.staticModal.hide();
    this.routesArr = [];
    this.response = false;
  }

  removeRoute(index: number) {
    this.routesArr.splice(index, 1);
  }

  showErrorDestinationModal(routeName: any) {
    Swal.fire({
      width: 600,
      title:
        '<p style="text-align: center;"><img style="display: block; margin-left: auto; ' +
        'margin-right: auto;" src="https://storage.googleapis.com/fleetflex-assets/oh_no_asset.png" alt="" /></p>',
      html:
        '<p style="text-align: center;font-size: medium;color: black"><strong>No se han podido subir la ruta "' +
        routeName +
        '"</strong></p>' +
        '<p><img src="https://storage.googleapis.com/fleetflex-assets/fields_error_markers.png" alt="" /></p>' +
        '<p style="text-align: center;font-size: medium;color: black"><strong>Se ha descargado una plantilla para revisar la información</strong></p>',
      confirmButtonText: 'Volver a intentar',
      confirmButtonColor: '#D43C30',
    }).then(result => {
      if (result.isConfirmed) {
        this.retryImport.emit(true);
      }
    });
  }

  /**
   * Import file
   *
   * @param formdata
   * @param formData
   */
  preImportFile(formData: FormData) {
    this.api.routes
      .preImport(formData)
      .then((res: any) => {
        if (res.isProblem) {
          saveAs(res.response, 'reimport_routes.xlsx');
          this.showErrorDestinationModal(res.routeName);
        } else {
          let routeObject = res.response.data.routes[res.routeName];
          console.log(routeObject);
          let referralGuidesPresence =
            this.checkReferralGuidesPresence(routeObject);
          this.routesArr.push(routeObject);
          this.importAll(res.routeName, referralGuidesPresence);
        }

        // if (res) {
        //
        //   this.routesImported = cloneDeep(res)
        //
        //   this.showModal()
        //
        //   let response:any = clone(res);
        //
        //   //this.analytics.sendAnalyticsEvent('Pre import routes from directory', {}, 'route');
        //
        //   this.routesCount = keysIn(response.data.routes).length;
        //
        //   this.errors = response.data.errors;
        //
        //   if(!this.routesCount){
        //     this.load = true;
        //     return
        //   }
        //
        //   this.load = true;
        //
        //   forEach(response.data.routes, (val) => {
        //     setTimeout(() => {
        //       this.routesArr.push(val)
        //       if (this.routesCount == this.routesArr.length){
        //         this.response = true;
        //       }
        //     }, 500);
        //   })
        //
        // }
      })
      .catch(error => {
        this.helper.handleErrorMessages(error);
      });
  }

  checkReferralGuidesPresence(route: any) {
    let control = false;
    route.deliveryPoints.forEach(point => {
      if (point.referralGuides != '') {
        control = true;
        return control;
      }
    });
    return control;
  }

  showSucessfullyRoutesUploadl(
    routeName: any,
    referralGuidesPresence: boolean,
    route: any
  ) {
    Swal.fire({
      width: 500,
      imageUrl:
        'https://storage.googleapis.com/fleetflex-assets/happy_check_asset.png',
      imageHeight: 150,
      title:
        '<br><p style="text-align: center;font-size: larger;color: #59B724"><strong>¡Listo para crear rutas!</strong></p>',
      html:
        '<br><p style="text-align: center;font-size: medium;color: black"><strong>Se han subido la ruta "' +
        routeName +
        '" con éxito</strong></p>',
      showCancelButton: true,
      confirmButtonColor: '#59B724',
      confirmButtonText: 'Asignar ruta',
      cancelButtonText: 'Guardar como plantilla',
      cancelButtonColor: '#979797',
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        this.assignRoute.emit(route);
        return true;
      }
      if (referralGuidesPresence) {
        if (result.isDenied || result.isDismissed) {
          Swal.fire({
            titleText:
              'Al guardar esta ruta como plantilla las guías de remisión se borrarán',
            showCancelButton: true,
            confirmButtonColor: '#979797',
            confirmButtonText: 'Guardar y asignar',
            cancelButtonText: 'Solo guardar',
            cancelButtonColor: '#979797',
          }).then(result => {
            if (result.isConfirmed) {
              this.assignRoute.emit(route);
            }
          });
        }
      } else {
        if (result.isDenied || result.isDismissed) {
          this.updateRoutes.emit(true);
        }
      }
    });
  }

  /**
   * Import all routes
   *
   * @param routeName
   * @param referralGuidesPresence
   */
  async importAll(routeName: any, referralGuidesPresence: boolean) {
    //if (!this.routesArr.length) return;

    let data = {
      routes: {},
    };

    this.routesArr.forEach(element => {
      data.routes[element.label] = element;
    });

    this.hideStaticModal();

    let finalRoute = {};

    await this.api.routes
      .importTemplates(data)
      .toPromise()
      .then(res => {
        this.selectedIndex = 0;
        this.routesArr = [];
        this.errors = [];
        this.routesError = res.data.errors;

        if (this.routesError.length) {
          console.log(res);
          this.showModal();
          this.helper.showErrorMessage(
            'Existen elementos que no se importaron'
          );
          this.analytics.sendAnalyticsEvent(
            'Failed route import from template',
            null,
            ''
          );
        } else {
          this.helper.showSuccessMessage('Se importó correctamente');
          this.analytics.sendAnalyticsEvent(
            'Route created by template',
            null,
            ''
          );
          console.log('ESTA ES LA RUTA CREADA', res.data.route);
          finalRoute = res.data.route;
          this.showSucessfullyRoutesUploadl(
            routeName,
            referralGuidesPresence,
            finalRoute
          );
        }
        //this.updateRoutes.emit(true);

        //this.analytics.sendAnalyticsEvent('Import all route', {}, 'route');
      })
      .catch(error => {
        this.helper.handleErrorMessages(error);
      });
  }

  retryUpload(any): void {
    this.hideStaticModal();
    this.retryImport.emit(any);
  }
}
