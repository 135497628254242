import { Component, OnInit, Input, Output, forwardRef } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { toString } from 'lodash';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { HelpersService } from '../..';
import { Subject } from 'rxjs';

@Component({
  selector: 'select-date-picker',
  templateUrl: './select-date-picker.component.html',
  styleUrls: ['./select-date-picker.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDatePickerComponent),
      multi: true,
    },
  ],
})
export class SelectDatePickerComponent implements OnInit {
  @Input() maxDate;
  @Input() minDate;
  @Input() date;
  @Input() placeholder: String;
  @Input() dateFormat;
  @Output() dateUpdated: Subject<any> = new Subject<any>();

  selectedDate: String;
  days: Array<String> = [];
  months: Array<String> = [];
  years: Array<String> = [];
  form: UntypedFormGroup;
  prevValue;

  selectedDay: string;
  selectedMonth: string;
  selectedYear: string;

  propagateChange: any = () => {};
  propageteTouch: any = () => {};
  validateFn: any = () => {};

  constructor(
    private helpers: HelpersService,
    private translate: TranslateService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.loadDatesPicker();
    this.onCheckChange();
  }

  /**
   * Init form fields
   */
  private initForm() {
    this.form = this.fb.group({
      day: [this.selectedDay ? this.selectedDay : '', Validators.required],
      month: [
        this.selectedMonth ? this.selectedMonth : '',
        Validators.required,
      ],
      year: [this.selectedYear ? this.selectedYear : '', Validators.required],
    });
  }

  ngOnChanges(changes: any) {
    if (changes.date) this.onCheckChange();
  }

  onCheckChange() {
    this.selectedDay = this.date
      ? moment(this.date, this.dateFormat).format('D')
      : '';
    this.selectedMonth = this.date
      ? moment(this.date, this.dateFormat).format('M')
      : '';
    this.selectedYear = this.date
      ? moment(this.date, this.dateFormat).format('YYYY')
      : '';

    if (this.selectedDay && this.selectedMonth && this.selectedYear)
      this.prevValue = moment(
        `${this.selectedYear}-${this.selectedMonth}-${this.selectedDay}`,
        'YYYY-M-D'
      ).format(this.dateFormat);

    this.initForm();
  }

  writeValue(value) {}

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn) {
    this.propageteTouch = fn;
  }

  private loadDatesPicker() {
    // fill days
    for (let i = 1; i <= 31; i++) {
      this.days[i] = i.toString();
    }

    // fill months
    for (let i = 1; i <= 12; i++) {
      this.months[i] = i.toString();
    }

    let startDate = this.minDate
        ? moment(this.minDate).format('YYYY')
        : moment().subtract(100, 'years').format('YYYY'),
      endDate = this.maxDate
        ? moment(this.maxDate).format('YYYY')
        : moment().add(50, 'years').format('YYYY');

    for (let from = +startDate, to = +endDate; from <= to; from++) {
      let y = from;
      this.years.push(toString(y));
    }
  }

  /**
   * Handle option selected
   *
   * @param selection
   * @param type
   */
  onSelectionChange(selection: string, type: string) {
    let day = type === 'day' ? selection : this.selectedDay;
    this.selectedDay = type === 'day' ? selection : this.selectedDay;
    let month = type === 'month' ? selection : this.selectedMonth;
    this.selectedMonth = type === 'month' ? selection : this.selectedMonth;
    let year = type === 'year' ? selection : this.selectedYear;
    this.selectedYear = type === 'year' ? selection : this.selectedYear;

    let newDate;

    if (day && month && year) {
      newDate = moment(`${year}-${month}-${day}`, 'YYYY-M-D').format(
        this.dateFormat
      );
    }

    if (newDate) {
      if (moment(newDate, this.dateFormat).isValid()) {
        this.selectedDate = newDate;
        this.date = newDate;
        this.onCheckChange();
        this.prevValue = newDate;
      } else {
        this.selectedDate = this.prevValue;
        this.date = this.prevValue;
        this.onCheckChange();
      }

      this.dateUpdated.next(this.selectedDate);
      // emit new date
      this.propagateChange(this.selectedDate);
    }
  }
}
