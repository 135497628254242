<mat-accordion
  *ngIf="assignedRoutes.length > 0; else noData"
  [togglePosition]="'before'">
  <mat-expansion-panel
    *ngFor="
      let route of onBoardingService.isStarted
        ? fakeRouteReportsData
        : assignedRoutes;
      let i = index;
      let isFirst = first
    "
    [expanded]="isFirst && onBoardingService.isStarted"
    hideToggle="true">
    <mat-expansion-panel-header
      class="table-header"
      [ngStyle]="{ 'justify-content': 'space-between' }">
      <div
        style="
          justify-content: space-between;
          width: 100%;
          display: flex;
          flex-direction: row;
          flex: 1;
        ">
        <mat-panel-title class="justify-content-center align-self-center">
          <div class="justify-content-center align-self-center">
            <mat-checkbox
              [(ngModel)]="route.checked"
              (change)="setCheckedValue(route)"
              [disabled]="!showDownloadCheck(route)"
              (click)="$event.stopPropagation()"></mat-checkbox>
          </div>
        </mat-panel-title>

        <mat-panel-title class="table-head">
          <div class="table-head-item">
            <span style="color: white; text-transform: capitalize">
              <mat-icon *ngIf="route?.isFastRoute">flash_on</mat-icon>
              {{ route?.label }}
            </span>
          </div>
        </mat-panel-title>

        <mat-panel-title class="table-head">
          <div class="table-head-item">
            <span style="color: white">{{
              route?.driver?.getFullName() || 'N/A'
            }}</span>
          </div>
        </mat-panel-title>

        <mat-panel-title class="thin-table-head">
          <div class="table-head-item">
            <span style="color: white">{{
              route?.date | date : 'dd/MM/yyyy'
            }}</span>
          </div>
        </mat-panel-title>

        <mat-panel-title class="thin-table-head resp">
          <div class="table-head-item">
            <span style="color: white">{{
              route?.index.length +
                ' ' +
                ('REPORTS.DESTINATIONS' | translate).toLowerCase()
            }}</span>
          </div>
        </mat-panel-title>

        <mat-panel-title class="table-head resp">
          <div class="table-head-item">
            <span style="color: white">{{
              ('REPORTS.TIME-IN-ROUTE' | translate) +
                ': ' +
                route?.getTimeInRoute()
            }}</span>
          </div>
        </mat-panel-title>

        <mat-panel-title class="table-head">
          <div class="table-head-item">
            <span style="color: white" *ngIf="!route?.isFinished()">{{
              route?.getStatusLabelFixture() | translate
            }}</span>

            <div class="status-with-icon" *ngIf="route?.isFinished()">
              <mat-icon
                [ngClass]="{
                  green: route?.status === 'completed',
                  yellow: route?.status === 'cancelled-inactivity',
                  red: route?.status === 'cancelled'
                }"
                >{{ route?.getStatusIconFixture() }}</mat-icon
              >
              <span style="color: white">{{
                route?.getStatusLabelFixture() | translate
              }}</span>
            </div>
          </div>
        </mat-panel-title>

        <button (click)="downloadReport(route)" mat-button>
          <i
            [ngStyle]="{ color: route?.isFinished() ? 'white' : '#4a5a62' }"
            class="material-icons"
            >get_app</i
          >
        </button>
      </div>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <div
        class="active-delivery-point tableSelector"
        [id]="'a' + (i + 1)"
        [ngClass]="{
          elevate:
            isFirst &&
            onBoardingService.isStarted &&
            onBoardingService.tutorialActualStep === 'report_table'
        }">
        <routes-report-table
          [route]="route"
          (previewOpenEvent)="previewOpen($event)"
          (previewCloseEvent)="previewClose($event)"
          (previewChangeEvent)="previewChange($event)"
          [statuses]="statuses">
        </routes-report-table>
      </div>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
<ng-template #noData>
  <div>NO TIENES RUTAS PARA HOY</div>
</ng-template>
<swal
  #emailSwal
  [title]="'Enviar PDF'"
  [confirmButtonText]="'Enviar'"
  [cancelButtonText]="'Cancelar'"
  [showCancelButton]="true"
  [focusCancel]="true"
  [showConfirmButton]="true"
  [width]="600"
  [confirmButtonColor]="'#385b70'"
  [cancelButtonColor]="'#385b70'">
  <div style="text-align: left; color: red" *swalPortal>
    <mat-form-field class="w-100">
      <mat-chip-grid #chipList>
        <mat-chip-row
          *ngFor="let email of emails"
          [selectable]="selectable"
          [ngStyle]="{ backgroundColor: email.bgColor }"
          [removable]="removable"
          (removed)="removeEmail(email)">
          {{ email.email }}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip-row>
        <input
          placeholder="Nuevo correo..."
          type="email"
          style="color: black"
          [matChipInputFor]="chipList"
          (paste)="addEmailsOnPaste($event, splitRegex)"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="addEmail($event)" />
      </mat-chip-grid>
    </mat-form-field>
    <span *ngIf="reportService.checkEmailsError(emails)"
      >Existen direcciones no válidas.</span
    >
  </div>
</swal>
