import { CircularMarkersDirective } from './circular-markers.directive';
import { OnlyNumber } from './numbers-only.directive';
import { CustomRenderDirective } from './custom-render.directive';
import { CustomScrollDirective } from './scroll.directive';
import { SafariHeaderDirective } from './safari.directive';
import { AppRoleDirective } from './app-role.directive';

/**
 *
 */
export function SharedDirectives() {
  return [
    CircularMarkersDirective,
    OnlyNumber,
    CustomRenderDirective,
    CustomScrollDirective,
    SafariHeaderDirective,
    AppRoleDirective,
  ];
}
