<div class="login-container">
  <mat-card appearance="outlined">
    <h1 class="title">{{ 'PROFILE.CHANGE-PASSWORD' | translate }}</h1>

    <form name="form" class="login-form" [formGroup]="updatePasswordForm">
      <!--ACTUAL PASSWORD -->
      <mat-form-field class="w-20 m-t-xs">
        <input
          matInput
          type="password"
          placeholder="{{ 'RESET-PASSWORD.ACTUAL-PASSWORD' | translate }}"
          value="123"
          name="password"
          formControlName="password" />
        <mat-error
          *ngIf="
            !updatePasswordForm.controls.password.valid &&
            updatePasswordForm.controls.password.touched
          ">
          {{ 'LOGIN.MESSAGES.REQUIRED-PASSWORD' | translate }}
        </mat-error>
      </mat-form-field>

      <!--NEW PASSWORD -->
      <mat-form-field class="w-20 m-t-xs">
        <input
          matInput
          type="password"
          placeholder="{{ 'RESET-PASSWORD.CONFIRM-PASSWORD' | translate }}"
          name="newPassword"
          formControlName="newPassword" />
        <mat-error
          *ngIf="
            !updatePasswordForm.controls.newPassword.valid &&
            updatePasswordForm.controls.newPassword.touched
          ">
          {{ 'LOGIN.MESSAGES.REQUIRED-PASSWORD' | translate }}
        </mat-error>
      </mat-form-field>

      <!--REPEAT NEW PASSWORD -->
      <mat-form-field class="w-20 m-t-xs">
        <input
          matInput
          type="password"
          placeholder="{{ 'RESET-PASSWORD.CONFIRM-NEW-PASSWORD' | translate }}"
          name="confirmPassword"
          formControlName="confirmPassword" />
        <!-- <mat-error *ngIf="(!updatePasswordForm.controls.confirmPassword.valid && updatePasswordForm.controls.confirmPassword.touched)" >
          {{"LOGIN.MESSAGES.REQUIRED-PASSWORD" | translate }}
        </mat-error> -->
      </mat-form-field>

      <div class="text-center">
        <button
          mat-raised-button
          color="primary"
          class="btn-save-changes"
          [disabled]="updatePasswordForm.invalid"
          (click)="updatePassword(updatePasswordForm)">
          {{ 'RESET-PASSWORD.SAVE-CHANGES' | translate }}
        </button>
      </div>
    </form>
  </mat-card>
</div>
