export * from './header/header.component';
export * from './uploader/uploader.component';
export * from './timepicker/timepicker.component';
export * from './side-profile/side-profile.component';
export * from './header-for-lists/header-for-lists.component';
export * from './toastr/toastr.component';
export * from './shared-cards-forms-headers/shared-cards-forms-headers.component';
export * from './detail-header/detail-header.component';
export * from './card-assignment';
export * from './card-assignment-row/card-assignment-row.component';
export * from './table-list-and-assign/table-list-and-assign.component';
export * from './header-for-lists/header-for-lists.types';
export * from './pagination-footer/pagination-footer.component';
export * from './notifications/notifications.component';
export * from './side-panel/side-panel.component';
export * from './notification-card/notification-card.component';
export * from './map-view-type/map-view-type.component';
export * from './notification-badge/notification-badge.component';
export * from './mini-loading/mini-loading.component';
export * from './points-stepper/points-stepper.component';
export * from './card-status/card-status.component';
export * from './card-route-head/card-route-head.component';
export * from './side-profile/submenu/submenu.component';
export * from './side-profile/customize-company/customize-company.component';
export * from './side-profile/activation-code/activation-code.component';
export * from './side-profile/email-preview/email-preview.component';
export * from './side-profile/notifications-settings/notifications-settings.component';
export * from './location-marker/location-marker.component';
export * from './route-polyline/route-polyline.component';
export * from './custom-datepicker/custom-datepicker.component';
export * from './inactive-account-check/inactive-account-check.component';
export * from './activation-modal/activation-modal.component';
export * from './activation-success/activation-success.component';
export * from './welcome-modal/welcome-modal.component';
export * from './range-time-picker/range-time-picker.component';
export * from './popover-clipboard/popover-clipboard.component';

// Main declarations
export * from './declarations';
