import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'app-onboarding-end-modal',
  templateUrl: './onboarding-end-modal.component.html',
  styleUrls: ['./onboarding-end-modal.component.sass'],
})
export class OnboardingEndModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<OnboardingEndModalComponent>
  ) {}

  ngOnInit(): void {}
  close() {
    this.dialogRef.close();
  }
}
