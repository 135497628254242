import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ApiRequestsService } from '../../../api';
import { HelpersService } from '../..';

@Component({
  selector: 'app-import-modal',
  templateUrl: './import-modal.component.html',
  styleUrls: ['./import-modal.component.sass'],
})
export class ImportModalComponent implements OnInit {
  @ViewChild('staticModal') staticModal: ModalDirective;
  @ViewChild('dropTarget') public dropTarget: ElementRef;
  @ViewChild('browseButton') public browseButton: ElementRef;

  @Input() data: any;

  secondTabColumns = ['field', 'sheet', 'line', 'error'];

  @Output() retryImport = new EventEmitter<boolean>();

  dataSource: Array<any> = [];

  constructor(
    private api: ApiRequestsService,
    private helper: HelpersService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: any) {
    if (changes.data) this.dataSource = this.data ? this.data.data : [];
  }

  /**
   * Show import Modal
   */
  public showModal(): void {
    this.staticModal.show();
  }

  /**
   * Hide import Modal
   */
  hideStaticModal(): void {
    this.staticModal.hide();
    this.data = {};
    this.dataSource = [];
  }

  retryUpload(any): void {
    this.hideStaticModal();
    this.retryImport.emit(any);
  }
}
