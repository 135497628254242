<div class="select-date-container">
  <span class="placeholder">{{ placeholder | translate }}</span>
  <form [formGroup]="form" class="select-dates">
    <mat-form-field class="m-r-xs">
      <mat-select
        formControlName="day"
        id="day"
        (change)="onSelectionChange($event.value, 'day')"
        name="day"
        placeholder="{{ 'COMMON.DAY' | translate }}"
        required>
        <mat-option *ngFor="let day of days" [value]="day">
          {{ day }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="m-r-xs">
      <mat-select
        formControlName="month"
        id="month"
        (change)="onSelectionChange($event.value, 'month')"
        name="month"
        placeholder="{{ 'COMMON.MONTH' | translate }}"
        required>
        <mat-option *ngFor="let month of months" [value]="month">
          {{ month }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="m-r-xs">
      <mat-select
        formControlName="year"
        id="year"
        (change)="onSelectionChange($event.value, 'year')"
        name="year"
        placeholder="{{ 'COMMON.YEAR' | translate }}"
        required>
        <mat-option *ngFor="let year of years" [value]="year">
          {{ year }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
