import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-password-updated',
  templateUrl: './password-updated.component.html',
  styleUrls: ['./password-updated.component.sass'],
})
export class PasswordUpdatedComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  goToProfile() {
    this.router.navigate(['/profile']);
  }
}
