import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import {
  AnalyticsService,
  HelpersService,
  OverlayServiceService,
} from '../../../services';
import { MatDialog } from '@angular/material/dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as moment from 'moment';
import { ReportExcelService } from '../../../services/report-excel.service';
import { ReportShareModalComponent } from '../../report-share-modal/report-share-modal.component';
import fakeRouteReports from 'assets/fakeData/routeReports';
import { OnboardingService } from '../../../services/onboarding.service';
import { Router } from '@angular/router';
import { ReportV2Service } from '../../../services/report-v2.service';
import { RouteModel } from '../../../../models/route.model';

@Component({
  selector: 'app-report-accordeon',
  templateUrl: './report-accordeon.component.html',
  styleUrls: ['./report-accordeon.component.sass'],
})
export class ReportAccordeonComponent implements OnInit {
  constructor(
    private analytics: AnalyticsService,
    public dialog: MatDialog,
    private helper: HelpersService,
    public reportService: ReportV2Service,
    public reportExcelService: ReportExcelService,
    private overlayService: OverlayServiceService,
    private onBoardingService: OnboardingService,
    private router: Router
  ) {}

  @Input() statuses: Array<any> = [];
  @Input() assignedRoutes: Array<RouteModel> = [];
  @ViewChild('emailSwal') private emailSwal: SwalComponent;
  @Output() previewOpenEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() previewChangeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() previewCloseEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() emitCheck: EventEmitter<any> = new EventEmitter();
  fakeRouteReportsData = fakeRouteReports.data;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  emails: Array<any> = [];
  splitRegex: RegExp = /[\n,]/;

  setCheckedValue(item) {
    this.emitCheck.emit(item._id);
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.overlayService.showOverlay(1);
    }, 10000);
  }

  previewChange($event) {
    this.previewOpenEvent.emit($event);
  }

  previewOpen($event) {
    this.previewOpenEvent.emit($event);
  }

  previewClose($event) {
    this.previewCloseEvent.emit();
  }

  reorderByIndex(route: any) {
    const orderedDeliver = [];
    route.index.forEach(point => {
      orderedDeliver.push(
        route.deliverPoints.find(
          delivery => delivery.deliveryPoint._id === point.id
        )
      );
    });
    route.deliverPoints = orderedDeliver;
  }

  showExportModal(message: any, item: any) {
    const dialogRef = this.dialog.open(ReportShareModalComponent, {
      data: {
        message: message,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const method = {
          method: 'download',
        };
        const reportContent = {
          route: item,
        };

        switch (result) {
          case 'PDF':
            method.method = 'download';

            this.reportService.getReport({
              type: 'route-individual',
              data: item,
              filters: {},
              method: 'download',
            });
            break;
          case 'EXCEL':
            method.method = 'download-excel';
            this.reportExcelService.generateReport({
              data: item,
              driver: item.driver,
              initDate: moment(item.date).valueOf(),
              endDate: moment(item.date).valueOf(),
              type: 'route-individual',
              route: null,
              status: null,
            });
            break;
          case 'EMAIL':
            this.emailSwal.fire().then(_result => {
              if (_result.isConfirmed) {
                let validMails = this.emails.filter(x => x.isValid === true);
                if (validMails.length > 0) {
                  validMails = validMails.map(x => x.email);
                  const method = {
                    method: 'sendEmail',
                    contacts: validMails,
                  };
                  this.reportService.getReport({
                    type: 'route-individual',
                    data: item,
                    filters: {},
                    method: 'sendEmail',
                    emails: validMails,
                  });
                } else {
                  this.helper.showErrorMessage(
                    'No existen destinatarios válidos para enviar reporte'
                  );
                }
              }
              if (_result.isDismissed) {
                this.emails = [];
              }
            });
            break;
        }
      }
    });
  }

  downloadReport(item: any) {
    if (
      item.status != 'pending' &&
      item.status != 'in-progress' &&
      item.status != 'paused-inactivity' &&
      item.status != 'late' &&
      item.status != 'starting-soon'
    ) {
      const message = `Desea generar reporte sobre la ruta "${item.label}"`;
      this.showExportModal(message, item);
    }
  }

  showDownloadCheck(item) {
    return (
      item.status !== 'pending' &&
      item.status !== 'in-progress' &&
      item.status !== 'paused-inactivity' &&
      item.status !== 'late' &&
      item.status !== 'starting-soon'
    );
  }

  addEmail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (value.trim() != '') {
      if (!this.emails.find(x => x.email === value.trim())) {
        const mail = {
          email: value.trim(),
          bgColor: '#b7b7b7',
          isValid: true,
        };
        const toSend = this.reportService.checkMail(mail);
        if (toSend) {
          this.emails.push(toSend);
        }
      } else {
        this.showRepeatedEmail();
      }
    }

    if (input) {
      input.value = '';
    }
  }

  removeEmail(email: string): void {
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }
  addEmailsOnPaste(event, separator) {
    let stringList = event.clipboardData.getData('text/plain');
    stringList = stringList.trim();
    const emailList = stringList.split(separator);
    for (let i = 0; i < emailList.length; i++) {
      const value = emailList[i];
      if (value.trim() != '') {
        if (!this.emails.find(x => x.email === value.trim())) {
          const mail = {
            email: value.trim(),
            bgColor: '#b7b7b7',
            isValid: true,
          };
          const toSend = this.reportService.checkMail(mail);
          if (toSend) {
            this.emails.push(toSend);
          }
        }
      }
    }
    event.target.setValue('');
  }

  showRepeatedEmail() {
    this.helper.showWarningMessage('Dirección de correo ingresada repetida');
  }

  onNextOnBoarding() {
    const dialogRef = this.dialog.open(ReportShareModalComponent, {
      data: {
        message:
          'Desea generar un reporte sobre las 9 rutas seleccionadas del 27 de julio de 2021 al 27 de agosto de 2021?',
      },
    });
    dialogRef.afterClosed().subscribe(value => {
      if (value !== 'back') {
        this.onBoardingService.nextStep('report_modal');
        this.router.navigate(['/dashboard']);
      }
    });
  }
  onPrevOnBoarding() {
    this.router.navigate(['/dashboard']);
  }
}
