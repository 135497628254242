<div
  class="modal fade"
  bsModal
  #staticModal="bs-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-import modal-lg m-t-xxl">
    <div class="modal-content">
      <div class="modal-header text-right">
        <button mat-icon-button (click)="hideStaticModal()">
          <i class="material-icons">close</i>
        </button>
        <h1 class="title">{{ 'IMPORT.TITLE_2' | translate }}</h1>
      </div>
      <div class="modal-body">
        <mat-tab-group>
          <mat-tab label="{{ 'IMPORT.TAB_2' | translate }}">
            <div class="table-tab-container">
              <mat-table #table [dataSource]="dataSource">
                <ng-container matColumnDef="field">
                  <mat-header-cell *matHeaderCellDef>
                    {{ 'IMPORT.FIELD' | translate }}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{ element.field }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="sheet">
                  <mat-header-cell *matHeaderCellDef>
                    {{ 'IMPORT.SHEET' | translate }}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{ element.sheet }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="line">
                  <mat-header-cell *matHeaderCellDef>
                    {{ 'IMPORT.LINE' | translate }}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{ element.line }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="error">
                  <mat-header-cell *matHeaderCellDef>
                    {{ 'IMPORT.PROBLEM' | translate }}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <span class="warning-color">
                      {{ element.error }}
                    </span>
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="secondTabColumns"></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: secondTabColumns"
                  class="incomplete-table"></mat-row>
              </mat-table>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div class="modal-footer">
        <!-- <div class="button-row pull-left">
          <button mat-button>
            <i class="material-icons">delete</i> {{ "IMPORT.DELETE_SELECTED" | translate }}
          </button>
        </div> -->
        <!-- <div class="pull-right">
          <button mat-button color="primary">{{ "IMPORT.IMPORT_SELECTED" | translate }}</button>
          <button mat-raised-button color="primary">{{ "IMPORT.IMPORT_ALL" | translate }}</button>
          <button mat-button color="primary" (click)="hideStaticModal()">{{ "COMMON.CANCEL" | translate }}</button>
        </div> -->
        <p class="m-n text-center">{{ 'IMPORT.NOTE' | translate }}</p>
        <button mat-button>
          <div class="upload">
            <input
              type="file"
              name="points"
              id="inputPoints"
              #inputPoints
              class="input-file"
              (change)="retryUpload($event)"
              accept=".xlsx, .xls, .csv" />
            <label for="inputPoints">
              {{ 'COMMON.RETRY-UPLOAD' | translate }}
            </label>
          </div>
        </button>
        <button mat-button (click)="hideStaticModal()">
          {{ 'COMMON.CANCEL' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
