import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiRequestsService } from '../../../api';
import {
  HelpersService,
  UserService,
  SidePanelService,
  AnalyticsService,
} from '../../services';
import { forEach } from 'lodash';
import { slideOutInAnimation } from '../../animations';

@Component({
  selector: 'app-side-profile',
  templateUrl: './side-profile.component.html',
  styleUrls: ['./side-profile.component.sass'],
  animations: [slideOutInAnimation],
})
export class SideProfileComponent implements OnInit, OnDestroy {
  picturePlaceholder = './assets/img/profile-placeholder.jpg';
  currentUser: any = {};
  companyData: any = {};
  showUserSideBar = false;
  menuItems: Array<any> = [];
  menuItems2: Array<any> = [];
  displayChild = false;
  selectedChild: any = {};

  private subscriptions: any = {};

  constructor(
    private router: Router,
    private api: ApiRequestsService,
    private helper: HelpersService,
    private userService: UserService,
    private sidePanel: SidePanelService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {
    this.getCurrentProfile();
    this.getCompanyData();

    this.menuItems = [
      {
        title: 'COMMON.FORM-CONFIG',
        action: () => this.goToFormConfiguration(),
        display: true,
      },
      {
        title: 'SIDE-MENU.TAGS.TITLE',
        icon: 'sell',
        childsType: 'tags',
        toRender: 'tag-creator',
        inChildView: true,
        action: item => this.triggerAction(item),
        display: true,
      },
    ];
    this.menuItems2 = [
      {
        title: 'SIDE-MENU.FAQ',
        action: () => this.goToFaq(),
        display: true,
      },
      {
        title: 'SIDE-MENU.CODES.TITLE',
        childsType: 'codes',
        action: item => this.triggerAction(item),
        display: true,
      },
      {
        title: 'SIDE-MENU.TEMPLATES',
        childsType: 'templates',
        action: item => this.triggerAction(item),
        display: true,
      },
    ];
  }

  goToUpdatePassword() {
    this.router.navigate(['./profile/change-password']);
    this.sidePanel.triggerClose.next(null);
  }

  ngOnDestroy() {
    forEach(this.subscriptions, value => value.unsubscribe());
  }

  /**
   * Close side bar
   */
  close() {
    this.sidePanel.triggerClose.next(null);
  }

  /**
   * Go to profile page
   *
   * @param edit profile
   */
  goToProfile(edit?: boolean) {
    if (!edit) {
      this.router.navigate(['./profile']);
    } else {
      this.router.navigate(['./profile/edit']);
    }
    this.sidePanel.triggerClose.next(null);
  }

  /**
   * Go to form configuration page
   */
  goToFormConfiguration() {
    this.router.navigate(['./form-configuration']);
    this.sidePanel.triggerClose.next(null);
  }

  /**
   * Go to FAQ
   */
  goToFaq() {
    this.router.navigate(['./faq']);
    this.sidePanel.triggerClose.next(null);
  }

  /**
   * Loggout user from app
   */
  logout() {
    this.analytics.logout();

    this.api.auth
      .logout()
      .then(() => {
        this.router.navigate(['/login']);
        this.currentUser = {};
      })
      .catch(err => this.helper.handleErrorMessages(err));
  }

  /**
   * Display child menu with their respective items
   *
   * @param item child menu
   */
  triggerAction(item?: any) {
    this.selectedChild = item;
    this.displayChild = true;
  }

  /**
   * Close sub menu
   */
  onChildMenuClose() {
    this.selectedChild = {};
    this.displayChild = false;
  }

  /**
   * Get current user profile
   */
  private getCurrentProfile() {
    this.subscriptions.company = this.api.user.me().subscribe(
      response => {
        localStorage.setItem('currentUserInfo', JSON.stringify(response));
        this.currentUser = response.data;
        this.userService.user = response.data;
        this.userService.onProfileLoad.next(response.data);
      },
      error => {
        this.helper.handleErrorMessages(error);
      }
    );
  }

  /**
   * Get company data
   */
  private getCompanyData() {
    let data = JSON.parse(localStorage.getItem('currentUserInfo'));

    if (data) {
      data = data.data;

      this.subscriptions.company = this.api.company
        .getOne(data.companyId)
        .subscribe(
          response => (this.companyData = response.data),
          error => this.helper.handleErrorMessages(error)
        );
    }
  }
}
