import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  CustomLoadingService,
  HelpersService,
  UserService,
  FirebaseService,
} from '../shared/services';
import { ApiRequestsService } from '../api/gateway.service';
import { forEach } from 'lodash';
import * as appUtils from 'app/utils';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass'],
})
export class LoginComponent {
  loginForm: UntypedFormGroup;
  hasError: boolean = false;
  isManagerDomain = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private api: ApiRequestsService,
    private loading: CustomLoadingService,
    private helpers: HelpersService,
    private userService: UserService,
    private firebaseService: FirebaseService
  ) {}

  ngOnInit() {
    this.checkDomain();
    this.initForm();
  }

  fetchFormConfig() {
    this.api.company
      .getFormConfiguration()
      .toPromise()
      .then(response => {
        this.helpers.setCompanyConfigurations(response.data);
      });
  }

  login({ value, valid }: { value: any; valid: boolean }) {
    let self = this;
    if (!valid) {
      forEach(this.loginForm.controls, (val, key) => {
        if (!val.valid) {
          this.loginForm.controls[key].markAsTouched();
          this.loginForm.controls[key].setErrors({ valid: false });
        }
      });
      return;
    }

    this.hasError = false;

    // this.loading.show()
    this.api.auth.login(value).subscribe(
      response => {
        self.userService.user = response.data;
        self.fetchFormConfig();
        if (this.isManagerDomain && !self.userService.user.isActive) {
          self.router.navigate(['/activateAccount']);
        } else {
          self.router.navigate(['/']);
        }
        self.firebaseService.getPermission().catch(err => err);
      },
      error => {
        self.hasError = true;
        self.loading.hide();
        if (
          error.userMessage.includes('combinación') ||
          error.userMessage.includes('combination')
        )
          error.userMessage = 'Contraseña incorrecta';
        if (
          error.userMessage.includes('dirección') ||
          error.userMessage.includes('address')
        )
          error.userMessage =
            'No hemos encontrado ningún usuario con este correo';
        self.helpers.handleErrorMessages(error);
      }
    );
  }

  /**
   * Init form fields
   */
  private initForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  checkDomain() {
    this.isManagerDomain = appUtils.isManagerDomain();
  }
}
