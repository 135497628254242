import { Component } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';
import { ApiRequestsService } from '../api';
import {
  CustomLoadingService,
  HelpersService,
  AnalyticsService,
  FirebaseService,
} from '../shared';
import { CustomValidators } from './validations';
import { isEmpty, forEach } from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.sass'],
})
export class SignupComponent {
  signupForm: UntypedFormGroup;
  data: any = {};

  constructor(
    private router: Router,
    private api: ApiRequestsService,
    private loading: CustomLoadingService,
    private helpers: HelpersService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private helper: HelpersService,
    private analytics: AnalyticsService,
    private firebaseService: FirebaseService
  ) {
    this.route.queryParams.subscribe(params => {
      if (!isEmpty(params)) this.data = params;
    });
  }

  ngOnInit() {
    this.initForm();
  }

  /**
   * Init form fields
   */
  private initForm(): void {
    this.signupForm = this.fb.group(
      {
        companyName: [this.data.company || '', Validators.required],
        email: [this.data.email || '', Validators.required],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: CustomValidators.Match('password', 'confirmPassword'),
      }
    );
  }

  /**
   * Submit register
   *
   * @param root0
   * @param root0.value
   * @param root0.valid
   */
  register({ value, valid }: { value: any; valid: boolean }): void {
    if (!valid) return;

    let toCapitalize = ['companyName'];

    forEach(value, (val, key) => {
      if (toCapitalize.indexOf(key) !== -1) {
        value[key] = this.helper.capitalizeText(val);
      }
    });

    this.api.auth.signup(value).subscribe(
      response => {
        this.analytics.registerUser({
          email: value.email,
          name: value.companyName,
        });

        this.analytics.sendAnalyticsEvent(
          'Company Created',
          {
            email: value.email,
            name: value.companyName,
            createdAt: moment.utc().toISOString(),
          },
          'registration'
        );

        this.router.navigate(['/drivers']);
        this.firebaseService.getPermission().catch(err => err);
      },
      error => {
        this.helpers.handleErrorMessages(error);
      }
    );
  }
}
