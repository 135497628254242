<div
  class="modal modal-import-container fade"
  bsModal
  #staticModal="bs-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-import modal-lg m-t-xxl">
    <div class="modal-content">
      <div class="modal-header">
        <button class="close-icon" mat-icon-button (click)="hideStaticModal()">
          <i class="material-icons">close</i>
        </button>
        <h1 class="title">{{ 'IMPORT.TITLE_ROUTE' | translate }}</h1>
      </div>
      <div class="modal-body">
        <div>
          <mat-tab-group
            [(selectedIndex)]="selectedIndex"
            *ngIf="load && !routesError.length">
            <mat-tab
              label="{{ 'IMPORT.TAB_1_ROUTES' | translate }} ({{
                routesArr.length
              }})">
              <div class="routes-content">
                <perfect-scrollbar class="panel-scroll">
                  <div *ngIf="!routesArr.length" class="placeholder">
                    <h3>{{ 'IMPORT.NOT-ROUTES' | translate }}</h3>
                  </div>
                  <div *ngIf="routesArr.length" class="routes-container">
                    <div
                      class="card-container"
                      *ngFor="let route of routesArr; let i = index">
                      <div class="card-holder">
                        <mat-card appearance="outlined" class="completed-card" role="group">
                          <div class="card-head-details">
                            <mat-card-title>
                              {{ route?.label ? route.label : '---' }}
                            </mat-card-title>
                          </div>

                          <div
                            class="delvery-point-info"
                            *ngFor="
                              let point of route.deliveryPoints;
                              let index = index
                            ">
                            <div class="delivery-icon">
                              <i
                                class="material-icons"
                                [ngClass]="{
                                  'pick-icon': point?.deliverType === 'pickUp',
                                  'deliver-icon':
                                    point?.deliverType !== 'pickUp',
                                  'pick-up': point?.deliverType === 'pickUp'
                                }">
                                {{ point?.icon ? point?.icon : '' }}
                                <span> {{ index + 1 }} </span>
                              </i>
                            </div>

                            <div class="info-holder">
                              <div class="route-item-name">
                                <span class="route-item-title capitalize-text">
                                  {{
                                    point?.deliveryName
                                      ? point?.deliveryName
                                      : point?.customerName
                                  }}
                                </span>
                                <span class="route-item-desc">
                                  {{ point?.address }}
                                </span>
                              </div>
                            </div>
                          </div>
                        </mat-card>

                        <div class="delete-button">
                          <button
                            mat-fab
                            color="warn"
                            (click)="removeRoute(i)"
                            class="btn-remove">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </perfect-scrollbar>
              </div>
              <div class="progress-bar-import" *ngIf="routesArr">
                <div
                  class="progress-bar-fill"
                  [ngStyle]="{
                    width: (routesArr.length * 100) / routesCount + '%'
                  }"
                  [ngClass]="{ 'w-full': response || !routesCount }"></div>
              </div>
            </mat-tab>
            <mat-tab
              label="{{ 'IMPORT.TAB_2_ROUTES' | translate }} ({{
                errors.length
              }})">
              <div class="table-tab-container">
                <div *ngIf="!errors.length" class="placeholder">
                  <h3>{{ 'IMPORT.NOT-ERRORS' | translate }}</h3>
                </div>
                <mat-table #table [dataSource]="errors" *ngIf="errors.length">
                  <ng-container matColumnDef="field">
                    <mat-header-cell *matHeaderCellDef>
                      {{ 'IMPORT.FIELD' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      {{ element.field }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="sheet">
                    <mat-header-cell *matHeaderCellDef>
                      {{ 'IMPORT.SHEET' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      {{ element.sheet }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="line">
                    <mat-header-cell *matHeaderCellDef>
                      {{ 'IMPORT.LINE' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      {{ element.line }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="error">
                    <mat-header-cell *matHeaderCellDef>
                      {{ 'IMPORT.PROBLEM' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <span class="warning-color">
                        {{ element.error }}
                      </span>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row
                    *matHeaderRowDef="secondTabColumns"></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: secondTabColumns"
                    class="incomplete-table"></mat-row>
                </mat-table>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
        <div>
          <mat-tab-group *ngIf="routesError.length">
            <mat-tab
              label="{{ 'IMPORT.ERRORS' | translate }} ({{
                routesError.length
              }})">
              <div class="table-tab-container">
                <mat-table
                  #table
                  [dataSource]="routesError"
                  *ngIf="routesError.length">
                  <ng-container matColumnDef="route">
                    <mat-header-cell *matHeaderCellDef>
                      {{ 'REPORTS.ROUTE' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      {{ element.route }}
                    </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="error">
                    <mat-header-cell *matHeaderCellDef>
                      {{ 'IMPORT.PROBLEM' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                      <span class="warning-color">
                        {{ element.error }}
                      </span>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row
                    *matHeaderRowDef="routesErrorsTab"></mat-header-row>
                  <mat-row
                    *matRowDef="let row; columns: routesErrorsTab"
                    class="incomplete-table"></mat-row>
                </mat-table>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div class="modal-footer">
        <div class="pull-right">
          <button mat-button>
            <div class="upload">
              <input
                type="file"
                name="points"
                id="inputPoints"
                #inputPoints
                class="input-file"
                (change)="retryUpload($event)"
                accept=".xlsx, .xls, .csv" />
              <label for="inputPoints">
                <span>{{ 'Volver a subir' | translate }}</span>
              </label>
            </div>
          </button>
          <button
            mat-raised-button
            color="primary"
            (click)="importAll()"
            *ngIf="response && routesArr.length">
            {{ 'IMPORT.IMPORT_ALL' | translate }}
          </button>
          <button
            mat-button
            class="uppercase"
            (click)="hideStaticModal()"
            *ngIf="!response || !routesArr.length">
            {{ 'COMMON.CANCEL' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
