import { CardAssignmentComponent } from './card-assignment.component';
import { CardHeaderDriverComponent } from './card-header-driver/card-header-driver.component';
import { CardHeaderVehicleComponent } from './card-header-vehicle/card-header-vehicle.component';
import { CardHeaderRouteComponent } from './card-header-route/card-header-route.component';
import { CardItemComponent } from './card-item/card-item.component';
import { CardAutocompleteComponent } from './card-autocomplete/card-autocomplete.component';
import { CardItemRouteComponent } from './card-item-route/card-item-route.component';
import { CardItemDriverComponent } from './card-item-driver/card-item-driver.component';
import { CardItemVehicleComponent } from './card-item-vehicle/card-item-vehicle.component';
import { CardDeleteConfirmationComponent } from './card-delete-confirmation/card-delete-confirmation.component';
import { CardCancelRouteComponent } from './card-cancel-route/card-cancel-route.component';

/**
 *
 */
export function CardAssignmentDeclarations() {
  return [
    CardAssignmentComponent,
    CardHeaderDriverComponent,
    CardHeaderVehicleComponent,
    CardHeaderRouteComponent,
    CardItemComponent,
    CardAutocompleteComponent,
    CardItemRouteComponent,
    CardItemDriverComponent,
    CardItemVehicleComponent,
    CardDeleteConfirmationComponent,
    CardCancelRouteComponent,
  ];
}
