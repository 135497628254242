import { HeaderComponent } from './header/header.component';
import { FileUploadComponent } from './uploader/uploader.component';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { SideProfileComponent } from './side-profile/side-profile.component';
import { CardAssignmentDeclarations } from './card-assignment';
import { CardAssignmentRowComponent } from './card-assignment-row/card-assignment-row.component';
import { HeaderForListsComponent } from './header-for-lists/header-for-lists.component';
import { PaginationComponent } from './pagination/pagination.component';
import { DetailHeaderComponent } from './detail-header/detail-header.component';
import { TableListAndAssignComponent } from './table-list-and-assign/table-list-and-assign.component';
import { SharedCardsHeaderComponent } from './shared-cards-forms-headers/shared-cards-forms-headers.component';
import { PaginationFooterComponent } from './pagination-footer/pagination-footer.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { SidePanelComponent } from './side-panel/side-panel.component';
import { NotificationCardComponent } from './notification-card/notification-card.component';
import { MapViewsTypesComponent } from './map-view-type/map-view-type.component';
import { NotificationBadgeComponent } from './notification-badge/notification-badge.component';
import { MiniLoadingComponent } from './mini-loading/mini-loading.component';
import { PointsStepperComponent } from './points-stepper/points-stepper.component';
import { CardStatusComponent } from './card-status/card-status.component';
import { CardRouteHeadComponent } from './card-route-head/card-route-head.component';
import { ItemChildViewComponent } from './side-profile/submenu/submenu.component';
import { CustomizeCompanyComponent } from './side-profile/customize-company/customize-company.component';
import { ActivationCodeComponent } from './side-profile/activation-code/activation-code.component';
import { EmailPreviewComponent } from './side-profile/email-preview/email-preview.component';
import { NotificationsSettingsComponent } from './side-profile/notifications-settings/notifications-settings.component';
import { LocationMarkerComponent } from './location-marker/location-marker.component';
import { RoutePolylineComponent } from './route-polyline/route-polyline.component';
import { CustomDatepickerComponent } from './custom-datepicker/custom-datepicker.component';
import { InactiveAccountCheckComponent } from './inactive-account-check/inactive-account-check.component';
import { ActivationModalComponent } from './activation-modal/activation-modal.component';
import { ActivationSuccessComponent } from './activation-success/activation-success.component';
import { WelcomeModalComponent } from './welcome-modal/welcome-modal.component';
import { RangeTimePickerComponent } from './range-time-picker/range-time-picker.component';
import { ReportNoContentComponent } from './report-no-content/report-no-content.component';
import { DestinationNoContentComponent } from './destination-no-content/destination-no-content.component';

/**
 *
 */
export function SharedComponents() {
  return [
    HeaderComponent,
    FileUploadComponent,
    TimepickerComponent,
    SideProfileComponent,
    HeaderForListsComponent,
    PaginationComponent,
    SharedCardsHeaderComponent,
    DetailHeaderComponent,
    CardAssignmentRowComponent,
    TableListAndAssignComponent,
    PaginationFooterComponent,
    NotificationsComponent,
    SidePanelComponent,
    NotificationCardComponent,
    MapViewsTypesComponent,
    NotificationBadgeComponent,
    MiniLoadingComponent,
    PointsStepperComponent,
    CardStatusComponent,
    CardRouteHeadComponent,
    ItemChildViewComponent,
    CustomizeCompanyComponent,
    EmailPreviewComponent,
    LocationMarkerComponent,
    RoutePolylineComponent,
    ActivationCodeComponent,
    CustomDatepickerComponent,
    InactiveAccountCheckComponent,
    ActivationModalComponent,
    ActivationSuccessComponent,
    WelcomeModalComponent,
    NotificationsSettingsComponent,
    RangeTimePickerComponent,
    CardAssignmentDeclarations(),
    ReportNoContentComponent,
    DestinationNoContentComponent,
  ];
}
