<div [ngClass]="{ unclickable: disabled }">
  <div class="d-flex justify-content-between mb-5" style="gap: 5px">
    <div
      class="border rounded-lg p-3 w-50 font-weight-bold text-center"
      [ngClass]="{ disabled: disabled }">
      {{ getDateLabel() }}
    </div>
    <div
      class="border rounded-lg p-3 w-50 font-weight-bold text-center"
      [ngClass]="{ disabled: disabled }">
      {{ getTimeLabel() }}
    </div>
  </div>
  <div class="text-center">
    <ngx-daterangepicker-material
      class=""
      (choosedDate)="choosedDate($event)"
      [autoApply]="true"
      [timePicker]="true"
      [startDate]="selected.startDate"
      [endDate]="selected.endDate"
      [linkedCalendars]="true">
    </ngx-daterangepicker-material>
  </div>
</div>
