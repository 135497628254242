// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { baseConfig } from './base-config';

const base_url: string = 'https://api-dev.fleetflex.io/';

const _environment = {
  production: false,
  development: true,
  defaultRole: 'company',

  // client
  client_id: 'fleetflex',
  client_secret: 'ed1fbd1eb41a11e7abc4cec278b6b50a',
  grant_type: 'password',
  googleMapsApiKey: 'AIzaSyBIcGUApRZZTBdIm3AXJiXHCcrcKiA3R8s',

  // firebase
  firebase: {
    apiKey: 'AIzaSyBmb2fRHcRAgnOYkFt0BdsrsnUFvcUAWfM',
    authDomain: 'fleetflex-dev-b5e08.firebaseapp.com',
    databaseURL: 'https://fleetflex-dev-b5e08.firebaseio.com',
    projectId: 'fleetflex-dev-b5e08',
    storageBucket: 'fleetflex-dev-b5e08.appspot.com',
    messagingSenderId: '942638448762',
    appId: '1:942638448762:android:a70d1448bf633db10cbbc3',
  },
  // file uploads
  upload_provider: 'GoogleCloudSigned',
  upload_drivers_bucket: 'fleetflex-drivers',
  upload_vehicles_bucket: 'fleetflex-vehicles',
  upload_company_bucket: 'fleetflex-drivers',
  upload_bucket_url: 'https://fleetflex-drivers.storage.googleapis.com',

  // mixpanel
  mixpanel_key: '7a8fa71b75115d9f3fd9eaa4764de9f8',

  // sentry
  sentry_key: 'https://e21e1b5ed13a4f1cbd3b61ab068b2762@sentry.io/1186087',

  // google tag manager
  ga: {
    url: 'https://www.googletagmanager.com/gtag/js?id=UA-118019885-7',
    ua: 'UA-118019885-8',
  },
};

export const environment = Object.assign(_environment, baseConfig(base_url));
