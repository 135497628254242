import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-select-all-check-controller',
  templateUrl: './select-all-check-controller.component.html',
  styleUrls: ['./select-all-check-controller.component.sass'],
})
export class SelectAllCheckControllerComponent implements OnInit {
  @Input() model: UntypedFormControl;
  @Input() values = [];
  @Input() text = 'Todos';
  @Input() labelPosition: 'before' | 'after' = 'after';

  constructor() {}

  isChecked(): boolean {
    return (
      this.model.value &&
      this.values.length &&
      this.model.value.length === this.values.length
    );
  }

  isIndeterminate(): boolean {
    return (
      this.model.value &&
      this.values.length &&
      this.model.value.length &&
      this.model.value.length < this.values.length
    );
  }

  toggleSelection(change: MatCheckboxChange): void {
    if (change.checked) {
      this.model.setValue(this.values);
    } else {
      this.model.setValue([]);
    }
  }

  ngOnInit(): void {}
}
