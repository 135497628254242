<mat-card
  appearance="outlined"
  class="notification-card"
  role="group"
  (mouseenter)="notification.readed = true"
  (mouseleave)="notification.readed = true"
  [ngClass]="{ readed: !notification.readed }">
  <span class="notification-time"> {{ notification?.createdAt }} </span>

  <mat-card-header
    class="notification-header"
    [ngClass]="{ 'has-menu': displayMenu }">
    <div class="notification-avatar">
      <img
        mat-card-avatar
        [src]="notification.image ? notification.image : picturePlaceholder" />
    </div>

    <div class="notification-head-details">
      <mat-card-subtitle class="m-b-n m-t-md">
        {{ notification?.title }}
      </mat-card-subtitle>
      <mat-card-title
        *ngIf="notification?.subtitle"
        [ngClass]="{ 'm-t-md': withoutTitle }">
        {{ notification?.subtitle }}
      </mat-card-title>
      <mat-card-subtitle
        *ngIf="notification?.details && willShow()"
        [ngClass]="{
          'warning-label': warningStatus,
          'important-label': importantStatus
        }">
        {{ notification?.details }}
      </mat-card-subtitle>
      <mat-card-subtitle *ngIf="notification?.details1 && !willShow()">
        {{ notification?.details1
        }}<span
          [ngClass]="{
            'warning-label': warningStatus,
            'important-label': importantStatus
          }">
          {{ notification?.details2 }}</span
        >
        {{ notification?.details3 }}
      </mat-card-subtitle>
    </div>
  </mat-card-header>

  <div class="button-row" *ngIf="notification?.button">
    <button
      mat-button
      color="primary"
      class="text-uppercase"
      (click)="triggerAction(notification?.link)">
      {{ notification?.button }}
    </button>
  </div>

  <div class="hover-options" *ngIf="displayMenu" [@flyOutIn]>
    <button class="delete" mat-button mat-icon-button (click)="remove()">
      <i class="material-icons"> delete </i>
    </button>
  </div>
</mat-card>

<swal
  #previewSwal
  [title]="modalTitle"
  [cancelButtonText]="'Cerrar'"
  [showCancelButton]="true"
  [focusCancel]="true"
  [showConfirmButton]="false"
  [width]="1200"
  [cancelButtonColor]="'#385b70'">
  <div *swalPortal class="table-body">
    <app-task-report-row
      *ngIf="this.type === 'task-added'"
      [points]="this.theObject.data"></app-task-report-row>
    <app-report-accordeon
      *ngIf="this.type === 'route-completed'"
      [assignedRoutes]="this.theObject.data"></app-report-accordeon>
  </div>
</swal>
