import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'app-options-modal',
  templateUrl: './options-modal.component.html',
  styleUrls: ['./options-modal.component.sass'],
})
export class OptionsModalComponent implements OnInit {
  message = 'Select one';
  confirmButtonText = 'Yes';
  cancelButtonText = 'Cancel';
  options = [{ text: 'Dummy Option', value: '1' }];
  optionSelected = this.options[0].value;
  constructor(
    public dialogRef: MatDialogRef<OptionsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
      if (data.options) {
        this.options = data.options;
        this.optionSelected = this.options[0].value;
      }
    }
  }

  ngOnInit() {}

  onConfirmClick(): void {
    this.dialogRef.close(this.optionSelected);
  }
}
