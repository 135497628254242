import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MatStepper } from '@angular/material/stepper';
import { ApiRequestsService } from '../../../api';
import { HelpersService, AnalyticsService, TourService } from '../../services';

@Component({
  selector: 'app-onboarding-tutorial',
  templateUrl: './onboarding-tutorial.component.html',
  styleUrls: ['./onboarding-tutorial.component.sass'],
})
export class OnboardingTutorialComponent implements OnInit {
  @ViewChild('staticModal') staticModal: ModalDirective;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('videoModal') videoModal: ModalDirective;

  currentPlayingVideo: HTMLVideoElement;
  videoSource: string;
  steps: Array<any> = [];
  stepsIcons: Array<any> = [];

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  constructor(
    private api: ApiRequestsService,
    private helper: HelpersService,
    private analytics: AnalyticsService,
    private tour: TourService
  ) {}

  ngOnInit() {
    this.steps = [
      {
        source: './assets/img/onboarding-1.png',
        sourceSet:
          './assets/img/onboarding-1@2x.png 2x, ./assets/img/onboarding-1@3x.png 3x',
        title: 'ONBOARDING.STEP-ONE.TITLE',
        active: true,
      },
      {
        source: './assets/img/onboarding-2.png',
        sourceSet:
          './assets/img/onboarding-2@2x.png 2x, ./assets/img/onboarding-2@3x.png 3x',
        title: 'ONBOARDING.STEP-TWO.TITLE',
        active: false,
      },
      {
        source: './assets/img/onboarding-3.png',
        sourceSet:
          './assets/img/onboarding-3@2x.png 3x, ./assets/img/onboarding-3@3x.png 3x',
        title: 'ONBOARDING.STEP-THREE.TITLE',
        active: false,
      },
      {
        source: './assets/img/onboarding-4.png',
        sourceSet:
          './assets/img/onboarding-4@2x.png 3x, ./assets/img/onboarding-4@3x.png 3x',
        title: 'ONBOARDING.STEP-FOUR.TITLE',
        active: false,
      },
    ];

    this.stepsIcons = [
      {
        active: './assets/img/driver-active.png',
        inactive: './assets/img/driver-inactive.png',
        activeSourceSet:
          './assets/img/driver-active2x.png 2x, ./assets/img/driver-active3x.png 3x',
        inactiveSourceSet:
          './assets/img/driver-inactive2x.png 2x, ./assets/img/driver-inactive2x.png 3x',
        title: 'ONBOARDING.STEP-ONE.TITLE',
      },
      {
        active: './assets/img/vehicle-active.png',
        inactive: './assets/img/vehicle-inactive.png',
        activeSourceSet:
          './assets/img/vehicle-active2x.png 2x, ./assets/img/vehicle-active3x.png 3x',
        inactiveSourceSet:
          './assets/img/vehicle-inactive2x.png 2x, ./assets/img/vehicle-inactive3x.png 3x',
        title: 'ONBOARDING.STEP-TWO.TITLE',
      },
      {
        active: './assets/img/route-active.png',
        inactive: './assets/img/route-inactive.png',
        activeSourceSet:
          './assets/img/route-active2x.png 2x, ./assets/img/route-active3x.png 3x',
        inactiveSourceSet:
          './assets/img/route-inactive2x.png 2x, ./assets/img/route-inactive3x.png 3x',
        title: 'ONBOARDING.STEP-THREE.TITLE',
      },
    ];
  }

  /**
   * Handler onboarding modal hidden event
   */
  handler() {
    if (
      this.staticModal.dismissReason === 'backdrop-click' ||
      this.staticModal.dismissReason === 'esc'
    )
      this.finishTutorial(true);
  }

  /**
   * Show Video Modal
   *
   * @param index
   */
  showVideoModal(index): void {
    this.videoModal.show();
  }

  /**
   * Hide Video Modal
   */
  hideVideoModal(): void {
    this.videoModal.hide();
    this.stopVideo();
  }

  /**
   * Show Onboarding Modal
   */
  showModal(): void {
    this.staticModal.show();
  }

  /**
   * Hide Onboarding Modal
   */
  hideStaticModal(): void {
    this.staticModal.hide();
  }

  /**
   * Go to next step
   */
  next(): void {
    this.stepper.next();
  }

  /**
   * Go to previous step
   */
  prev(): void {
    this.stepper.previous();
  }

  /**
   * Change stepper
   *
   * @param index of the step
   */
  changeStepper(index): void {
    this.stepper.selectedIndex = index;
  }

  /**
   * Pause current playing video
   */
  stopVideo(): void {
    if (this.currentPlayingVideo) this.currentPlayingVideo.pause();
  }

  /**
   * Get current video element
   *
   * @param event
   */
  onPlayingVideo(event): void {
    this.currentPlayingVideo = event.target;
  }

  /**
   * Update display tutorial
   *
   * @param backdrop
   */
  finishTutorial(backdrop?: boolean) {
    this.hideStaticModal();
    this.tour.showTour();
  }
}
