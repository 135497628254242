<mat-accordion>
  <mat-expansion-panel
    *ngFor="let routeDeliveryPoint of route.deliverPoints; index as i"
    (opened)="routeDeliveryPoint.panelOpenState = true; sendAnalytics()"
    (closed)="routeDeliveryPoint.panelOpenState = false"
    class="mr-5"
    [ngClass]="{ 'odd-row': i % 2 === 0 }">
    <mat-expansion-panel-header class="panel-separator">
      <!--      Indice-->
      <mat-panel-title class="align-items-start flex-column pr-5">
        <div>
          <p class="item-title">
            {{ i + 1 + ':' }}
          </p>
        </div>
      </mat-panel-title>

      <!--      Punto de entrega-->
      <mat-panel-title class="align-items-start flex-column pr-5">
        <div class="table-head-item">
          <span> {{ 'Nombre de la empresa' | translate }} </span>
        </div>
        <div>
          <p class="item-title wrap-title" style="text-transform: capitalize">
            {{ routeDeliveryPoint?.deliveryPoint?.deliveryName }}
          </p>
        </div>
      </mat-panel-title>

      <!--      Ubicacion-->
      <mat-panel-title class="align-items-start flex-column pr-5">
        <div class="table-head-item">
          <span> {{ 'Ubicación de destino' | translate }} </span>
        </div>
        <div
          *ngIf="
            routeDeliveryPoint?.deliveryPoint?.latitude &&
            routeDeliveryPoint?.deliveryPoint?.longitude &&
            route.type !== 'fast'
          ">
          <div
            class="d-flex align-items-center"
            (click)="openMapModal(routeDeliveryPoint)"
            [ngClass]="{
              'warning-color': routeDeliveryPoint.isOutOfFence(),
              'item-link-title': !routeDeliveryPoint.isOutOfFence()
            }">
            <u
              class="text-wrap"
              data-toggle="tooltip"
              data-placement="top"
              [title]="routeDeliveryPoint?.deliveryPoint?.address">
              {{ routeDeliveryPoint?.deliveryPoint?.address }}
            </u>
            <span>
              <i
                *ngIf="!routeDeliveryPoint.isOutOfFence()"
                class="material-icons"
                style="transform: rotate(135deg)">
                link
              </i>
              <i
                *ngIf="routeDeliveryPoint.isOutOfFence()"
                class="material-icons warning-color m-r-xs ml-1">
                warning
              </i>
            </span>
          </div>
        </div>
      </mat-panel-title>

      <!--      Sector-->
      <mat-panel-title class="align-items-start flex-column pr-5">
        <div class="table-head-item">
          <span> {{ 'Sector' | translate }} </span>
        </div>
        <div>
          <p class="item-title wrap-title">
            {{ routeDeliveryPoint?.deliveryPoint?.warehouse || 'N/A' }}
          </p>
        </div>
      </mat-panel-title>

      <!--Hora de gestion-->
      <mat-panel-title class="align-items-start flex-column pr-5">
        <div class="table-head-item">
          <span> {{ 'Hora de gestión' | translate }} </span>
        </div>

        <div>
          <p class="item-title">
            {{ (routeDeliveryPoint?.documentedAt | date : 'hh:mm a') || '' }}
          </p>
        </div>
      </mat-panel-title>

      <!--      Estado-->
      <mat-panel-title class="align-items-start flex-column item-title pr-5">
        <div class="table-head-item">
          <span> {{ 'Estado' | translate }} </span>
        </div>
        <div class="d-flex">
          <div *ngIf="routeDeliveryPoint.status === ''">
            <i
              *ngIf="routeDeliveryPoint.status.delivered"
              class="material-icons success-color m-r-xs"
              >check_circle</i
            >
            <i
              *ngIf="!routeDeliveryPoint.status.delivered"
              class="material-icons error-color m-r-xs"
              >cancel</i
            >
            {{
              (!routeDeliveryPoint.status.delivered
                ? 'CARD-ASSIGNMENT.INCOMPLETED'
                : 'DASHBOARD.CARDS.OK'
              ) | translate
            }}
          </div>
          <div *ngIf="routeDeliveryPoint.status" style="display: contents">
            <i
              *ngIf="routeDeliveryPoint.status === 'completed'"
              class="material-icons success-color m-r-xs"
              >check_circle</i
            >
            <i
              *ngIf="routeDeliveryPoint.status === 'rejected'"
              class="material-icons error-color m-r-xs"
              >cancel</i
            >
            <i
              *ngIf="routeDeliveryPoint.status === 'incomplete'"
              class="material-icons warning-color m-r-xs"
              >error_outline</i
            >
            <!--<i *ngIf="item.status === 'cancelled-inactivity'" class="material-icons warning-color m-r-xs">cancel</i>-->
            <p
              class="item-title"
              [ngClass]="{
                'success-color': routeDeliveryPoint.status === 'completed',
                'warning-color': routeDeliveryPoint.status === 'incomplete',
                'error-color': routeDeliveryPoint.status === 'rejected'
              }">
              {{
                (routeDeliveryPoint.status === 'completed'
                  ? statuses.completed.text
                  : routeDeliveryPoint.status === 'incomplete'
                  ? statuses.incomplete.text
                  : routeDeliveryPoint.status === 'cancelled-inactivity'
                  ? 'REPORTS.STATUS.CANCELLED-INACTIVITY'
                  : routeDeliveryPoint.status === 'rejected'
                  ? statuses.rejected.text
                  : routeDeliveryPoint.status === 'cancelled'
                  ? 'REPORTS.STATUS.CANCELLED'
                  : ''
                ) | translate
              }}
            </p>
          </div>
          <div
            *ngIf="
              !routeDeliveryPoint.status ||
              getOldStatus(routeDeliveryPoint) === ''
            ">
            <i
              *ngIf="getOldStatus(routeDeliveryPoint) === 'completed'"
              class="material-icons success-color m-r-xs"
              >check_circle</i
            >
            <i
              *ngIf="getOldStatus(routeDeliveryPoint) === 'rejected'"
              class="material-icons error-color m-r-xs"
              >cancel</i
            >
            <i
              *ngIf="getOldStatus(routeDeliveryPoint) === 'incomplete'"
              class="material-icons warning-color m-r-xs"
              >error_outline</i
            >
            {{
              (getOldStatus(routeDeliveryPoint) === 'completed'
                ? 'DASHBOARD.CARDS.OK'
                : getOldStatus(routeDeliveryPoint) === 'incomplete'
                ? 'CARD-ASSIGNMENT.INCOMPLETED'
                : getOldStatus(routeDeliveryPoint) === 'rejected'
                ? 'CARD-ASSIGNMENT.REJECTED'
                : ''
              ) | translate
            }}
          </div>
          <div *ngIf="!routeDeliveryPoint.status" class="item-title">
            {{
              (route.status.includes('cancelled')
                ? 'REPORTS.STATUS.CANCELLED'
                : 'REPORTS.STATUS.PENDING'
              ) | translate
            }}
          </div>
        </div>
      </mat-panel-title>

      <!--Etiquetas-->
      <mat-panel-title class="align-items-start flex-column">
        <div class="table-head-item">
          <span> {{ 'Etiquetas' | translate }} </span>
        </div>
        <div class="tags-wrapper">
          <drag-scroll
            [scrollbar-hidden]="true"
            [drag-scroll-y-disabled]="true"
            [scroll-x-wheel-enabled]="true"
            class="drag-container">
            <mat-chip-listbox>
              <div class="tag-row" *ngFor="let tag of routeDeliveryPoint?.tags">
                <mat-chip [ngStyle]="{ 'background-color': tag.color }">
                  {{ tag.label }}
                </mat-chip>
              </div>
            </mat-chip-listbox>
          </drag-scroll>
          <i
            class="material-icons item-icon"
            (click)="openTagsSelectorModal(routeDeliveryPoint); sendEvent()">
            sell
          </i>
        </div>
      </mat-panel-title>

      <mat-panel-title class="align-items-start flex-column">
        <div class="d-flex">
          <div class="btn-details">
            <span class="uppercase-text">
              {{ 'REPORTS.MORE-DETAILS' | translate }}
            </span>
            <i *ngIf="!routeDeliveryPoint.panelOpenState" class="material-icons"
              >expand_more</i
            >
            <i *ngIf="routeDeliveryPoint.panelOpenState" class="material-icons"
              >expand_less</i
            >
          </div>

          <div style="margin-left: 20px" *ngIf="hasStatus(routeDeliveryPoint)">
            <mat-icon (click)="downloadReport(routeDeliveryPoint, route)"
              >file_download</mat-icon
            >
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="d-flex">
      <div class="w-75 pl-4 pr-4">
        <div class="delivery-table-grid-row">
          <div class="collapse-item-5">
            <span class="table-collapse-title-black">
              {{ 'Información del destino' | translate }}
            </span>
          </div>

          <div class="collapse-item-5">
            <span class="table-collapse-title-slate">
              {{ 'Nombre del contacto' | translate }}:
            </span>
            <div class="d-flex align-items-center">
              <mat-menu
                class="guide"
                #matCustomerNameMenu
                style="background: #385b70">
                <app-popover-clipboard
                  [guides]="
                    routeDeliveryPoint?.deliveryPoint?.customerName
                  "></app-popover-clipboard>
              </mat-menu>

              <p style="text-transform: capitalize">
                {{
                  (routeDeliveryPoint?.deliveryPoint?.customerName || []
                    | joinArryas : ',') || 'N/A'
                }}
              </p>

              <mat-icon
                *ngIf="routeDeliveryPoint?.deliveryPoint?.customerName?.length"
                class="view-popover"
                mat-icon-button
                [matMenuTriggerFor]="matCustomerNameMenu"
                >add</mat-icon
              >
            </div>
          </div>

          <div class="collapse-item-5">
            <span class="table-collapse-title-slate">
              {{ 'Coordenadas de gestión' | translate }}:
            </span>
            <div>
              <a
                class="d-flex align-items-center"
                [href]="
                  getMapUrl(
                    routeDeliveryPoint?.latitude,
                    routeDeliveryPoint?.longitude
                  )
                "
                target="_blank">
                <span class="item-link-title wrap-title">
                  <u>
                    {{ toFixedCoord(routeDeliveryPoint?.latitude) }},{{
                      toFixedCoord(routeDeliveryPoint?.longitude)
                    }}
                  </u>
                </span>
                <span
                  ><i class="material-icons" style="transform: rotate(135deg)"
                    >link</i
                  ></span
                >
              </a>
            </div>
          </div>

          <div class="collapse-item-5">
            <span class="table-collapse-title-slate">
              {{ 'Email' | translate }}:
            </span>
            <div class="d-flex align-items-center">
              <mat-menu class="guide" #matEmailMenu style="background: #385b70">
                <app-popover-clipboard
                  [guides]="
                    routeDeliveryPoint?.deliveryPoint?.customerMail
                  "></app-popover-clipboard>
              </mat-menu>

              <p style="text-transform: capitalize">
                {{
                  (routeDeliveryPoint?.deliveryPoint?.customerMail || []
                    | joinArryas : ',') || 'N/A'
                }}
              </p>

              <mat-icon
                *ngIf="routeDeliveryPoint?.deliveryPoint?.customerMail?.length"
                class="view-popover"
                mat-icon-button
                [matMenuTriggerFor]="matEmailMenu"
                >add</mat-icon
              >
            </div>
          </div>

          <div class="collapse-item-5">
            <span class="table-collapse-title-slate">
              {{ 'Teléfono' | translate }}
            </span>
            <div class="d-flex align-items-center">
              <mat-menu class="guide" #matPhoneMenu style="background: #385b70">
                <app-popover-clipboard
                  [guides]="
                    routeDeliveryPoint?.deliveryPoint?.phone
                  "></app-popover-clipboard>
              </mat-menu>

              <p style="text-transform: capitalize">
                {{
                  (routeDeliveryPoint?.deliveryPoint?.phone || []
                    | joinArryas : ',') || 'N/A'
                }}
              </p>

              <mat-icon
                *ngIf="routeDeliveryPoint?.deliveryPoint?.phone.length"
                class="view-popover"
                mat-icon-button
                [matMenuTriggerFor]="matPhoneMenu"
                >add</mat-icon
              >
            </div>
          </div>
        </div>

        <div class="delivery-table-grid-row">
          <div class="collapse-item-5">
            <span class="table-collapse-title-black">
              {{ 'Detalle de gestión' | translate }}
            </span>
          </div>

          <div
            *ngFor="
              let custom_field of routeDeliveryPoint?.custom_fields;
              let i = index
            ">
            <div class="collapse-item-5">
              <span class="table-collapse-title-slate">
                {{ custom_field.label || 'N/A' | translate }}:
              </span>
              <div>
                <p style="text-transform: capitalize">
                  {{ custom_field.value || 'N/A' | translate }}
                </p>
              </div>
            </div>
          </div>

          <div class="collapse-item-5">
            <span class="table-collapse-title-slate">
              {{ 'Fotos' | translate }}:
            </span>
            <div>
              <p style="text-transform: capitalize">
                {{ routeDeliveryPoint?.files.length || 'N/A' | translate }}
              </p>
            </div>
          </div>
        </div>

        <div
          class="delivery-table-grid-row"
          style="grid-template-columns: 2fr repeat(3, 1fr)">
          <div class="">
            <mat-menu class="guide" #menu="matMenu" style="background: #385b70">
              <app-popover-clipboard
                [guides]="
                  routeDeliveryPoint.getReferralGuidesValue()
                "></app-popover-clipboard>
            </mat-menu>
            <span class="table-collapse-title-slate">
              {{ 'Guías de remisión:' | translate }}
            </span>
            <drag-scroll
              *ngIf="routeDeliveryPoint?.referralGuides.length > 0"
              [scrollbar-hidden]="true"
              [drag-scroll-y-disabled]="true"
              style="height: auto; display: flex">
              <mat-chip-listbox style="max-width: 320px">
                <mat-chip-option
                  *ngFor="let guide of routeDeliveryPoint?.referralGuides"
                  >{{ guide?.referralGuide }}</mat-chip-option
                >
              </mat-chip-listbox>
              <mat-icon
                class="view-popover"
                mat-icon-button
                [matMenuTriggerFor]="menu"
                >add</mat-icon
              >
            </drag-scroll>
          </div>

          <div class="">
            <span class="table-collapse-title">
              {{ 'Detalle de gestión' | translate }}:
            </span>
            <perfect-scrollbar
              class="comment"
              style="white-space: pre-line; word-wrap: break-word">
              <p>
                {{
                  getComments(routeDeliveryPoint) || 'No hay Detalle de gestión'
                    | translate
                }}
              </p>
            </perfect-scrollbar>
          </div>
        </div>

        <div class="delivery-table-grid-row">
          <div class="collapse-item-5">
            <span class="table-collapse-title-black">
              {{ 'Información del receptor' | translate }}
            </span>
          </div>

          <div class="collapse-item-5">
            <span class="table-collapse-title-slate">
              {{ 'Nombre del receptor' | translate }}:
            </span>
            <div>
              <p style="text-transform: capitalize">
                {{ routeDeliveryPoint?.receiverName || 'N/A' | translate }}
              </p>
            </div>
          </div>

          <div class="collapse-item-5">
            <span class="table-collapse-title-slate">
              {{ 'Cédula' | translate }}:
            </span>
            <div>
              <p>{{ routeDeliveryPoint?.receiverId || 'N/A' | translate }}</p>
            </div>
          </div>

          <div class="collapse-item-5">
            <span class="table-collapse-title-slate">
              {{ 'Firma' | translate }}:
            </span>
            <div>
              <p>
                {{
                  helpers.hasSignature(routeDeliveryPoint) || 'N/A' | translate
                }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-column w-25 pl-4 pr-4">
        <div class="collapse-item">
          <div>
            <ngx-gallery
              class="gallery"
              *ngIf="routeDeliveryPoint.files"
              [options]="galleryOptions"
              [images]="getFiles(routeDeliveryPoint.files)"
              (previewOpen)="
                sendAnalyticsPhoto($event, routeDeliveryPoint, driver)
              "
              (previewClose)="previewClose($event)"
              (previewChange)="
                previewChange2($event, routeDeliveryPoint, driver)
              "
              #galeriaNg>
            </ngx-gallery>
          </div>
        </div>
        <div class="collapse-item">
          <div class="sign-container">
            <div class="sign-content">
              <img
                [ngStyle]="{ height: '110px' }"
                *ngIf="routeDeliveryPoint.signatureUrl"
                [src]="routeDeliveryPoint.signatureUrl" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<swal
  #emailSwal
  [title]="'Enviar PDF'"
  [confirmButtonText]="'Enviar'"
  [cancelButtonText]="'Cancelar'"
  [showCancelButton]="true"
  [focusCancel]="true"
  [showConfirmButton]="true"
  [width]="600"
  [confirmButtonColor]="'#385b70'"
  [cancelButtonColor]="'#385b70'">
  <div style="text-align: left; color: red" *swalPortal>
    <mat-form-field class="w-100">
      <mat-chip-grid #chipList>
        <mat-chip-row
          *ngFor="let email of emails"
          [selectable]="selectable"
          [ngStyle]="{ backgroundColor: email.bgColor }"
          [removable]="removable"
          (removed)="remove(email)">
          {{ email.email }}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip-row>
        <input
          placeholder="Nuevo correo..."
          type="email"
          style="color: black"
          [matChipInputFor]="chipList"
          (paste)="addEmailsOnPaste($event, splitRegex)"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)" />
      </mat-chip-grid>
    </mat-form-field>
    <span *ngIf="reportService.checkEmailsError(emails)"
      >Existen direcciones no válidas.</span
    >
  </div>
</swal>
