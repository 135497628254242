<div class="login-container">
  <mat-card appearance="outlined">
    <form name="form" class="login-form">
      <!--ACTUAL PASSWORD -->
      <img src="/assets/img/password-updated.png" alt="" />

      <p class="mt-5 mb-5">{{ 'RESET-PASSWORD.FINISHED-MSG' | translate }}</p>

      <div class="text-center">
        <button
          mat-raised-button
          color="primary"
          class="btn-save-changes"
          (click)="goToProfile()">
          {{ 'RESET-PASSWORD.FINISHED' | translate }}
        </button>
      </div>
    </form>
  </mat-card>
</div>
