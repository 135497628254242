import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ApiRequestsService } from '../../../api';
import { HelpersService, AnalyticsService } from '../../services';
import { clone } from 'lodash';
import { OnboardingTutorialComponent } from '../onboarding-tutorial/onboarding-tutorial.component';

@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.sass'],
})
export class ProfileModalComponent implements OnInit {
  @ViewChild('staticModal') staticModal: ModalDirective;
  @ViewChild(OnboardingTutorialComponent)
  public tutorialModal: OnboardingTutorialComponent;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  constructor(
    private api: ApiRequestsService,
    private helper: HelpersService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {}

  /**
   * Show Profile Modal
   */
  showModal(): void {
    this.staticModal.show();
  }

  /**
   * Hide Profile Modal
   */
  hideStaticModal(): void {
    this.staticModal.hide();
    localStorage.setItem('profileCompleted', 'completed');
  }

  /**
   * Update display tutorial
   */
  skipTutorial() {
    let data = JSON.parse(localStorage.getItem('currentUserInfo'));

    if (data) data = data.data;

    let _data = clone(data);

    let payload = {
      displayTutorial: false,
    };

    this.api.user.update(payload).subscribe(
      response => {
        localStorage.setItem('currentUserInfo', JSON.stringify(response));

        this.analytics.sendAnalyticsEvent(
          'Skip tutorial',
          {
            companyId: _data.companyId,
            email: _data.email,
          },
          'tutorial'
        );
        this.hideStaticModal();
      },
      error => {
        this.helper.handleErrorMessages(error);
      }
    );
  }

  /**
   * Show tutorial
   */
  showTutorial(): void {
    this.hideStaticModal();
    this.tutorialModal.showModal();
  }
}
