<div>
  <mat-dialog-content>
    <h3>{{ message }}</h3>

    <div>
      <mat-radio-group class="radio-group" [(ngModel)]="optionSelected">
        <mat-radio-button
          class="radio-button"
          *ngFor="let option of options"
          [value]="option.value">
          {{ option.text | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button
      mat-raised-button
      class="fleetflexButton"
      (click)="onConfirmClick()"
      tabindex="1">
      {{ confirmButtonText }}
    </button>
    <button
      mat-raised-button
      mat-dialog-close
      tabindex="-1"
      class="fleetflexButton">
      {{ cancelButtonText }}
    </button>
  </mat-dialog-actions>
</div>
