import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  CustomLoadingService,
  HelpersService,
  UserService,
  FirebaseService,
  MustMatch,
} from '../shared/services';
import { ApiRequestsService } from '../api/gateway.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.sass'],
})
export class ChangePasswordComponent implements OnInit {
  updatePasswordForm: UntypedFormGroup;
  hasError: boolean = false;
  currentUser: any = {};

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private api: ApiRequestsService,
    private loading: CustomLoadingService,
    private helpers: HelpersService,
    private userService: UserService,
    private firebaseService: FirebaseService,
    private translate: TranslateService,
    private helper: HelpersService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  /**
   * Init form fields
   */
  private initForm() {
    // this.updatePasswordForm = this.formBuilder.group({
    //   password: ['', Validators.required],
    //   newPassword: ['', Validators.required],
    //   confirmPassword: ['', Validators.required],
    // });

    //

    //let aux = reset ? '' : '123456789';
    // this.passwordForm = this.formBuilder.group({
    //   password: [{ value: aux, disabled: !this.isEdit }, Validators.required ],
    //   newPassword: [{ value: '', disabled: !this.isEdit }, Validators.required]
    // });

    this.updatePasswordForm = this.formBuilder.group(
      {
        password: ['', Validators.required],
        newPassword: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: MustMatch('newPassword', 'confirmPassword'),
      }
    );
  }

  updatePassword({ value, valid }: { value: any; valid: boolean }) {
    if (!valid) {
      _.forEach(this.updatePasswordForm.controls, (val, key) => {
        if (!val.valid) {
          this.updatePasswordForm.controls[key].markAsTouched();
          this.updatePasswordForm.controls[key].setErrors({ valid: false });
        }
      });
      return;
    }

    let data = JSON.parse(localStorage.getItem('currentUserInfo'));
    value.email = data.data.email;

    let obj = {
      email: value.email,
      password: value.password,
      newPassword: value.newPassword,
    };

    this.api.user.changePassword(obj).subscribe(
      response => {
        //let title = this.translate.instant('COMMON.PASSWORD');
        //let message = this.translate.instant('PROFILE.MESSAGES.PASSWORD-UPDATED');

        //this.helper.showSuccessMessage(message, title);
        this.userService.onProfileUpdate.next('');
        this.router.navigate(['/profile/password-updated']);
      },
      error => {
        this.helper.handleErrorMessages(error);
      }
    );
  }

  public matchValues(
    matchTo: string // name of the control to match to
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
  }
}
